<template>
    <div class="news-container">
        <h1>บทความทั้งหมด</h1>
        <div class="blog-container">
            <div v-if="articles.length" class="article-wrapper">
                <a :href="`/post/${id}`" class="article-card" v-for="({ id, topic, created_at, cover, /*views*/ }, i) in articles" :key="i.key">
                    <img style="width: 100%" :src="cover" />
                    <div class="card-content">
                        <span style="margin-bottom: 1rem">{{compare_time(created_at)}}</span>
                        <a :href="`/post/${id}`"><h1 style="margin: 0.3rem 0" class="line-clamp">{{topic}}</h1></a>
                        <a :href="`/post/${id}`"><p :id="`sub_topic_${i}`" class="line-clamp"></p></a>
                        <div class="divider"></div>
                        <!-- <div style="display: flex; justify-content: end;">
                            <span>{{views}} views</span>
                        </div> -->
                    </div>
                </a>
            </div>
            <div v-else>
                <img style="width: 10rem" :src="loading_img_url"/>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import NewsService from '../resources/news_service';

export default {
    name: 'news-blog',
    data () {
        return {
            articles: [
                {id:'1',topic:'สนามกีฬาใกล้ Iconsiam',created_at:new Date('2023-11-26'),cover:require('../assets/newbg.png'),views:0},
                {id:'2',topic:'ลดอาการบาดเจ็บก่อนลงแข่งบาสด้วยท่าการ warm',created_at:new Date('2023-11-26'),cover:require('../assets/newbg.png'),views:0},
            ],
            loading_img_url: 'https://cdn.discordapp.com/attachments/780994260471775272/983601360404615178/S__41820187.gif'
        }
    },
    methods: {
        compare_time(datetime) {
            return moment(datetime,'YYYY-MM-DD HH:mm:ss').fromNow();
        },
        async get_all_news() {
            const new_service = new NewsService()
            const { articles } = await new_service.all_news()
            this.articles = articles
        },
        dom_parser(text, parent) {
            const target = document.querySelector(`#${parent}`)
            target.appendChild(this.createFragment(text))
        },
        createFragment(htmlStr) {
            var fragment = document.createDocumentFragment(),
                $elem = document.createElement('div');
                $elem.innerHTML = htmlStr;
            while($elem.firstChild) {
                fragment.appendChild($elem.firstChild);
            }
            return fragment;
        },
        init_article() {
            this.articles.forEach(({ sub_topic },i) => {
                if (sub_topic) this.dom_parser(sub_topic, `sub_topic_${i}`)
            })
        },
    },
    async mounted() {
       // await this.get_all_news()
        this.init_article()
    }
}
</script>

<style scoped>
    a {
        color: black;
        text-decoration: none;
    }
    a:hover {
        color: #e21515
    }
    .divider {
        background-color: black;
        height: 1px;
        opacity: 0.2;
        margin: 1rem 0;
    }
    .line-clamp {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }
    .blog-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .news-container {
        padding: 4rem
    }
    .article-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: auto
    }
    .article-card {
        cursor: pointer;
        margin: 2rem;
        width: 25vw;
        border: 1px solid#e3e3e3bf;
        display: flex;
        flex-direction: column;
    }
    .card-content {
        padding: 1.5rem;
    }
</style>